import ScreenLock from './lock-screen';

const Navigation = function navigation() {
    const toggleBtn = document.getElementById('btn-toggle-nav');
    const $pageHeader = document.querySelector('.page-header-bar');
    const $nav = document.querySelector('.nav');
    const headerElements = [$pageHeader, $nav];

    toggleBtn.addEventListener('click', () => toggleNavigation('data-nav'));

    function addDataNavAttr($el, attr) {
       $el.setAttribute(attr, 'open');
       ScreenLock.lock();
    }

    function removeAttribute($el, attr) {
        $el.removeAttribute(attr);
        ScreenLock.unlock();
    }

    function toggleNavigation(attr) {
        headerElements.map($el => {
            console.log($el.getAttribute(attr));
            $el.getAttribute(attr) === 'open' ?
                removeAttribute($el, attr) :
                addDataNavAttr($el, attr);
        });
    }
};

export default Navigation;