import cookies from './cookies';
import even from './even';

const contrastMode = function() {
    const $toggleBtn = document.querySelector('.icon-contrast');
    const $body = document.querySelector('body');
    const $elements = [$body];

    $toggleBtn.addEventListener('click', toggleContrast);

    function addContrastClass() {
        $elements.map(element => {
            element.classList.add('contrast-mode');
            
            cookies.setCookie('contrastMode', true, 999);

            jQuery('*').each(function(){
            var k =  parseInt(jQuery(this).css('font-size')); 
            var redSize = ((k*110)/100);
                jQuery(this).css('font-size',redSize);
            });

        });

        even();
    }

    function removeContrastClass() {
        $elements.map(element => {
            element.classList.remove('contrast-mode');
            cookies.removeCookie('contrastMode');
            location.reload();
        });
    }

    function toggleContrast() {
        if ( $body.classList.contains('contrast-mode') ) {
            removeContrastClass();
        } else {
            addContrastClass();
        }
    }

    jQuery(document).ready(function() {
        if (cookies.readCookie('contrastMode')) {
            addContrastClass();
        }
    });
}

export default contrastMode;