const cookies = {
    setCookie(cookie_name, cookie_value, expire_days) {
        var date = new Date();
        date.setTime(date.getTime() + (expire_days * 24 * 60 * 60 * 1000));
        var expires = "expires=" + date.toUTCString();
        document.cookie = cookie_name
                        + "="
                        + cookie_value
                        + "; "
                        + expires
                        + ';path=/;';
      },
      
      readCookie(cookie_name) {
        var name = cookie_name + "=";
        var cookies_array = document.cookie.split(';');
        for (var i = 0; i < cookies_array.length; i++) {
            var cookie = cookies_array[i];
            while (cookie.charAt(0) == ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) == 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        return "";
      },
      
      removeCookie(cookie_name) {
        document.cookie = cookie_name
                        + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
                        + ';path=/;';
      }
}

export default cookies;


