import "@babel/polyfill";
import $ from 'jquery';
import 'bootstrap';
//import ScrollReveal from 'scrollreveal';
import './style.scss';
//import Router from './util/Router';
//import common from './routes/common';
//import home from './routes/home';
//import cookies from './util/cookies';
//import even from './util/even';
import Video from './util/video';
import Navigation from './util/navigation';
import even from './util/even';
import contrastMode from './util/contrastMode';
import MicroModal from 'micromodal';
import './util/modal';
import './util/wpAjax';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */

/*
const routes = new Router({
  common,
  home,
});
*/
window.jQuery = $;
window.$ = $;

/** Load Events */
//$(document).ready(() => routes.loadEvents());

//console.log(observer);
//window.sr = ScrollReveal({ move: 0 });

window.addEventListener('load', () => {
	Video();
	Navigation();
	even();
	contrastMode();

	if (window.location.hash) {
		var modal = window.location.hash.substring(1);
		MicroModal.show(modal);
	}
/*
	MicroModal.init({
        onShow: modal => console.info(`${modal.id} is shown`), // [1]
        onClose: modal => console.info(`${modal.id} is hidden`), // [2]
        disableScroll: false, // [5]
        disableFocus: false, // [6]
    });*/

	$('body').on('click', '.btn-square', function(){
		var micromodal_btn = $(this).data('micromodal-trigger');
		if(history.pushState) {
		    history.pushState(null, null, '#'+micromodal_btn);
		} else {
		    location.hash = micromodal_btn;
		}
	});

	$('body').on('click', '.modal__close', function(){
		window.history.pushState('', '/', window.location.pathname)
	});

    $('.menu li').hover(function(){
		$(this).find('.sub-menu').stop().slideDown();
	}, function(){
		$(this).find('.sub-menu').stop().slideUp();
	});
});

window.addEventListener('resize', () => {

});

function removeHash () { 
    var scrollV, scrollH, loc = window.location;
    if ("pushState" in history)
        history.pushState("", document.title, loc.pathname + loc.search);
    else {
        // Prevent scrolling by storing the page's current scroll offset
        scrollV = document.body.scrollTop;
        scrollH = document.body.scrollLeft;

        loc.hash = "";

        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
    }
}
