(function($) {

    const $inputSearch = $('input[name="job-filter-search"]');
    const ajaxData = {
        'action': 'job_filter'
    }
    const $jobListWrapper = $('.job-list-wrapper');
    const $jobFilterForm = $('#job-filter-form');
    let $dataPageValue = $jobListWrapper.data('page');
    
    
    $('#job-filter-form').on('submit', function(event) {
        event.preventDefault();
        ajaxData.paged = 1;
        ajax();
    });
    
    $(document).on('click', '.job-load-more', function(e) {
        e.preventDefault();
        const inputText = $inputSearch.attr('value');
        ajaxData.paged = $dataPageValue;
        ajax('load-more');
    });
    
    const ajax = function(dataModifer = 'submit') {
        ajaxData.searchQuery = $inputSearch.attr('value');
        $.ajax({
            url: WP['ajaxUrl'],
            type: 'POST',
            data: ajaxData,
            success: function (responseData) {
                if ( dataModifer === 'submit' ) {
                    $dataPageValue = 2;
                    $jobListWrapper.html(responseData);
                } else {
                    $dataPageValue += 1;
                    $jobListWrapper.append(responseData);
                }
                $jobListWrapper.attr('data-page', $dataPageValue).data('page', $dataPageValue);
            }
        })
    };

    if ( $jobFilterForm.length ) {
        $jobFilterForm.submit();
    }
})(jQuery);
