import MicroModal from 'micromodal';

(function() {
    MicroModal.init({
        onShow: modal => console.info(`${modal.id} is shown`), // [1]
        onClose: modal => console.info(`${modal.id} is hidden`), // [2]
        disableScroll: false, // [5]
        disableFocus: false, // [6]
    });
})();

