const Video = function initVideoFrame() {
	const frames = Array.prototype.slice.call(document.querySelectorAll('iframe[src*="youtube"], iframe[src*="vimeo"]'));
	for (let i = 0; i < frames.length; i += 1) {
		const html = document.createElement('div');
		html.classList.add('video-container');
		html.innerHTML = frames[i].outerHTML;
		frames[i].parentNode.insertBefore(html, frames[i]);
		frames[i].remove();
	}
};

export default Video;
