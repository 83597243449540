import evenColumns from './evenColumns';

const $ = jQuery.noConflict();
const $title = $('.box-grid-item h3, .event-list-item h2');
const $text = $('.box-grid-item .box-grid-item-text, .even-list-item span');
const $item = $('.box-grid-item');

function setEvenColumns($el) {
	if ($el.length) {
		$el.evenColumns(
			{
				columns: 3,
			},
			{
				columns: 2,
				maxScreenWidth: 769,
			},
			{
				columns: 1,
				maxScreenWidth: 575,
			}
		);
	}
}

function even() {
	setEvenColumns($title);
	setEvenColumns($text);
	setEvenColumns($item);
}

export default even;